import { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import ThePlan from "./pages/ThePlan";
import Training from "./pages/Training";
import Stories from "./pages/Stories";

function App() {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash !== "") {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) element.scrollIntoView();
      }, 20);
    }
  });

  return (
    <div>
      <Routes>
        <Route path="/" element={<ThePlan />} />
        <Route path="/training" element={<Training />} />
        <Route path="/stories" element={<Stories />} />
      </Routes>
    </div>
  );
}

export default App;
