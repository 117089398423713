import Nav from "../components/Nav";
import Footer from "../components/Footer";

function ThePlan() {
  return (
    <div>
      <Nav />

      <div id="content" className="section">
        <h2>The Plan</h2>

        <p>
          We recognise that providing nursing and residential care is complex
          and providing excellent care requires different disciplines to work
          together. Physiotherapy is our speciality and our plan allows you to
          predictably and reliably provide the very best physiotherapy to your
          residents.
          <ul>
            <li>We provide specialist older adult physiotherapy</li>
            <li>
              An easy, cost effective service for your care home so you are more
              attractive to prospectve residents and families
            </li>
            <li>Helping you to meet your CQC excellent rating</li>
          </ul>
        </p>

        <p>
          For a monthly fee per resident we provide:
          <ul>
            <li>
              An initial physiotherapy assessment of
              each resident, goals and a treatment plan
            </li>
            <li>A physiotherapy annual review</li>
            <li>Up to an hour of treatment for each resident every month</li>
            <li>Staff training day quarterly</li>
          </ul>
        </p>
        <p>
          With further treatment sessions available if required at additional
          cost.
        </p>
      </div>

      <Footer />
    </div>
  );
}

export default ThePlan;
