import Nav from "../components/Nav";
import Footer from "../components/Footer";

function Training() {
  return (
    <div>
      <Nav />

      <div id="content" className="section">
        <h2>Training</h2>
        <p>
          We provide quarterly face to face or virtual training sessions to the
          care team. Enhancing their knowledge and skills to ensure excellent
          care. Choose your bespoke package:
        </p>
        <li>Postural care in dementia</li>
        <li>Occupational therapy in older adults</li>
        <li>Therapeutic support in complex care</li>
        <li>Learning disability awareness (CQC mandatory training)</li>
        <li>Mental capacity awareness</li>
        <li>Specialist transfer and adaptive equipment</li>
        <li>Exercising safely</li>
        <li>Stroke rehabilitation</li>
        <li>Airway clearance, effective cough and breathlessness management</li>
        <li>Oral suction in the community</li>
        <li>Resolving complex moving and handling problems</li>
        <li>Parkinsons disease</li>
        <li>Falls prevention</li>
        <li>Equipment: what&apos;s new</li>
        <li>Long covid and fatigue management</li>
        <li>Health promotion and physical activity in older adults</li>
      </div>

      <Footer />
    </div>
  );
}

export default Training;
