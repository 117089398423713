function Footer() {
  return (
    <div id="contact" className="section">
      <h2>Contact</h2>
      <p>Drop us an email or follow us on social media to stay updated.</p>
      <br />
      <p>
        <a className="button" href="mailto:sian@midwinterphysiotherapy.co.uk">
          Get in touch
        </a>
      </p>

      <div id="socials">
        {/* <div className="text-left" style={{ fontSize: "1rem" }}></div> */}
        <div className="text-right">
          <a href="https://www.facebook.com/midwinterphysio/">
            <i className="fa-brands fa-facebook-f"></i>
          </a>
          <a href="https://www.instagram.com/midwinterphysio/?hl=en">
            <i className="fa-brands fa-instagram"></i>
          </a>
          <a href="https://www.linkedin.com/in/midwinterphysio/">
            <i className="fa-brands fa-linkedin"></i>
          </a>
          <a href="https://goo.gl/maps/EQM68rrV6JRs8e6n8">
            <i className="fa-brands fa-google"></i>
          </a>
          <br />
          <a
            href="https://midwinterphysiotherapy.co.uk"
            style={{
              fontSize: "0.8rem",
              display: "inline-block",
              marginTop: "20px",
            }}
          >
            by Midwinter Physiotherapy
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
