import Nav from "../components/Nav";
import Footer from "../components/Footer";

function Stories() {
  return (
    <div>
      <Nav />

      <div id="content" className="section">
        <h2>Real Stories</h2>
        <div className="story">
          <p>
            Jane is an 87 year old lady who fell and sustained a periprosthetic
            fracture to her distal femur, she moved into a nursing home until
            she could potentially return to her own home. She had to wait over 4
            weeks for the NHS physiotherapist due to waiting lists. We were
            called in by her family and assessed her the same week.
          </p>
          <p>
            She was to be managed conservatively with a full leg brace and non
            weight bearing, she required hoisting for transfers and was able to
            sit independently. Her treatment plan was to build her tolerance to
            sit out daily using the hoist with the care team, strengthening
            exercises to hip flexors, quadriceps and ankles as well as clot
            prevention and pressure relieving exercises. Jane progressed to
            standing with stand aid, we taught the care staff how to safely
            remove brace and clean leg and brace and how to hoist with straight
            leg brace in situ. She progressed to a hinge brace and was allowed
            specific range of motion every 2 weeks, we supported the staff to
            perform this safely. We handed over to the NHS team who could
            continue her care.
          </p>
          <p>
            6 months later we were contacted again as she was now mobile with a
            frame in her supported living apartment but very nervous and
            continuing to fall. Jane would like to gain more independence but
            her NHS physio has discharged her so we returned and were able to
            progress her mobility, increase her confidence, reduced her number
            of falls as well as her falls risk. We continue to support this lady
            with regular input due to her frailty.
          </p>
        </div>

        <div className="story">
          <p>
            Donald is a 78 year old man with dementia, the care home were
            struggling to mobilise him. On assessment he was able to follow
            prompts but did not have the mental capacity to make decisions. We
            worked with the team to ensure a capacity assessment was undertaken
            and that physiotherapy was in his best interests. We determined the
            best way to engage Donald to stand and gave him and the care team
            the tools to assist him safely. This included practising weight
            transfer forwards, placing the frame much further in front of him to
            allow him to transfer his weight and guiding him with appropriate
            handling. Once stood Donald was able to walk in a straight line with
            his frame around the care home, he needed a lot of help with turning
            as this is a much more complex procedure.
          </p>
          <p>
            As he gradually went off his feet as is the nature of dementia we
            supported the care staff with appropriate positioning to avoid him
            developing the well recognised tenting foetal position and losing
            his ability to swallow due to loss of sensory and proprioceptive
            control. We were able to support him to die in dignity with dementia
            and provide excellent care and support.
          </p>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Stories;
