function Nav() {
  return (
    <div id="header">
      <div id="background"></div>
      <div className="maxwidth">
        <nav>
          <ul>
            <li>
              <a href="/#content">The Plan</a>
            </li>
            <li>
              <a href="/training#content">Training</a>
            </li>
            <li>
              <a href="/stories#content">Stories</a>
            </li>
          </ul>
        </nav>
        <div>
          <h1>
            <span>Care</span>
            <br />
            <span style={{ color: "#EF199F" }}>Fully</span>
          </h1>
          <h3>Improving quality of life when we need it most</h3>
          <hr />
          <p>
            We provide specialist therapy services to care providers to help
            their residents live their best lives.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Nav;
